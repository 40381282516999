// If you don't want to use TypeScript you can delete this file!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import ApiLogo from "../../../components/images/logo.api"
import GoToInstall from "../../../components/widgets/goToInstall"
import { CodeBlock } from "../../../components/widgets/codeBlock"
import { clienteScripts } from "../../../scripts/clientes"
import SectionNavigate from "../../../components/widgets/sectionNavigate"

type DataProps = {
    site: {
        buildTime: string
    }
}

const ApiReferenciaLanding: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isApi={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ApiDocs' className="_DOCS withSide">
            <div className="content">
                <h2>Buscar cliente</h2>
                <p>
                    Hay dos formas de buscar clientes, toda la lista de ellos, o por authToken
                </p>
                <h3>
                    Buscar todos los clientes
                </h3>
                <pre>
                    <code>
                        <b>GET:</b> https://api.fiscalpop.com/api/v1/clients/find/<b><span className="error">:masterToken</span></b>
                    </code>
                </pre>
                <h4>
                    Campos de la Respuesta
                </h4>
                <ul className="request">
                    <li>
                        <p>
                            <b>rfc: </b> RFC emisor del cliente
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>regimenFiscal: </b> La clave del régimen fiscal, según las establecidas por el SAT. <br />
                            <span className="small quote">Para conocer las claves de régimen fiscal, entra <Link to="../../catalogo-regimen">aquí</Link></span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>nombre: </b> Nombre o razón social del cliente.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>lugarExpedicion: </b> Codigo postal donde está dado de alta el emisor.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>isProduction: </b> Declara si el cliente es producción o para prueba (true / false) <br />
                            <span className="small quote">Los clientes de prueba tienen que usar RFCs y certificados especiales, obtén los datos para prueba <Link to="../clientes-certificados">aquí</Link></span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>authToken: </b> Esta es <span className="error">la llave de operación de tu cliente</span>, cada vez que quiera emitir, cancelar u obtener facturas, así como consultar los catálogos del SAT a través de nuestro API.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>masterToken: </b> Esta es <span className="error">la llave de acceso principal</span>, confirmando la clave usada para crear el cliente.
                        </p>
                    </li>
                </ul>
                <br />
                <br />
                <h3>
                    Buscar un cliente por AuthToken
                </h3>
                <p>
                    Buscar cliente por authToken permite que tu sistema pueda obtener el perfil del cliente por su AuthToken, siendo esta variable la única necesaria a guardar en tu sistema.
                </p>
                <p>
                    Por esta ruta <b>MasterToken nunca se regresa, </b>de forma que esta ruta puede ser usada de forma segura para obtener el perfil del cliente.
                </p>
                <pre>
                    <code>
                        <b>GET:</b> https://api.fiscalpop.com/api/v1/clients/findBy/<b><span className="error">:authToken</span></b>
                    </code>
                </pre>
                <h4>
                    Campos de la Respuesta
                </h4>
                <ul className="request">
                    <li>
                        <p>
                            <b>rfc: </b> RFC emisor del cliente
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>regimenFiscal: </b> La clave del régimen fiscal, según las establecidas por el SAT. <br />
                            <span className="small quote">Para conocer las claves de régimen fiscal, entra <Link to="../../catalogo-regimen">aquí</Link></span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>nombre: </b> Nombre o razón social del cliente.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>lugarExpedicion: </b> Codigo postal donde está dado de alta el emisor.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>isProduction: </b> Declara si el cliente es producción o para prueba (true / false) <br />
                            <span className="small quote">Los clientes de prueba tienen que usar RFCs y certificados especiales, obtén los datos para prueba <Link to="../clientes-certificados">aquí</Link></span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>authToken: </b> Esta es <span className="error">la llave de operación de tu cliente</span>, cada vez que quiera emitir, cancelar u obtener facturas, así como consultar los catálogos del SAT a través de nuestro API.
                        </p>
                    </li>
                </ul>
                <SectionNavigate next={`/api/referencia/clientes-billing`} previous={`/api/referencia/clientes-borrar`}/>
            </div>
            <div className="side">
                <CodeBlock title="Buscar Clientes" requests={clienteScripts.buscar.requests} response={clienteScripts.buscar.response} />
            </div>
        </div>
    </Layout>
)

export default ApiReferenciaLanding
